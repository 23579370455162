import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  background: linear-gradient(270deg, #14D1AA 0%, #0A5282 100%);
`;

export const Title = styled.h1`
  width: 90%;
  max-width: 1100px;
  margin: 0px 0px 15px 5vw;

  color: #FFF;
  font-size: 50px;
  font-style: italic;
  font-weight: 200;
  line-height: normal;

  @media (max-width: 1150px) {
    text-align: center;
    font-style: normal;
    font-size: 40px;
  }

  @media (max-width: 700px) {
    font-size: 26px;
  }
`;

export const Options = styled.div`
  display: flex;
  column-gap: 30px;
  max-width: 90%;
  width: 1100px;

  a{
    div{
      filter: grayscale(0);
    }
    h3{
      color: #16B499;
    }

    &:hover{
      h3{
        color: #16B499;
      }
    }
  }

  @media (min-width: 1151px) {
    .option:hover{
      width: 210px;
      height: 560px;
      margin: -5px;
    }
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    row-gap: 30px;
  }

  @media (max-width: 700px) {
    row-gap: 20px;
  }
`;

export const Option = styled.div`
  width: 200px;
  height: 550px;
  // flex-shrink: 0;

  background-image: linear-gradient(rgba(9, 79, 129, 0.70), rgba(9, 79, 129, 0.70)), url(${(props) => props.$bgImage});
  background-size: cover;
  background-position: center center;
  transition: all 0.2s;
  filter: grayscale(1);

  display: flex;
  justify-content: center;
  align-items: center;

  .name{
    background: #fff;
    padding: 15px 20px 15px 10px;
    border-radius: 16px;
    transition: all 0.2s;
  }

  img{
    width: 120px;
  }

  h3{
    color: #B6B6B6;
    text-align: right;
    font-family: 'Michroma', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: -15px 0px 0px;
  }

  .description{
    position: absolute;
    top: calc(50% + 50px);
    text-align: right;
    opacity: 0;
    padding: 10px;
    transition: all 0.2s;
    p{
      padding: 10px 5px;
      color: #fff;
      font-size: 0.64em;
      font-weight: 300;
    }
  }

  &:hover{
    .description{
      opacity: 1;
    }
  }

  @media (min-width: 1151px) {
    transform: skew(-10deg);
    
    .name{
      transform: skew(10deg);
    }
  }

  @media (max-width: 1150px) {
    width: 100%;
    height: 120px;

    img{
      width: 100px;
    }

    h3{
      font-size: 22px;
    }

    .description{
      display: none;
    }
  }

  @media (max-width: 700px) {
    img{
      width: 80px;
    }

    h3{
      font-size: 20px;
    }

    .name{
      padding: 8px 10px 8px 10px;
      border-radius: 8px;
    }
  }
`;

export const LogoutWrap = styled.div`
  width: 1200px;
  margin: 0 auto 20px;
  max-width: 90%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  div{
    cursor: pointer;
    margin-right: 0;
    padding: 6px 16px;
    background: #C8C8C8;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 16px;
    transition: all 0.1s;

    img{
      width: 20px;
    }

    span{
      margin-left: 12px;
    }

    &:hover{
      background: #646464;
    }
  }
`;
